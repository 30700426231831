import React, { useCallback, useMemo } from 'react';

import { QBox, QMenuButton, QMenuItemType } from '@qualio/ui-components';

import { useFlags } from 'launchdarkly-react-client-sdk';
import { QualioDocumentSection } from '../../../api/model/document';
import { EditorName } from '../../../config/handlers/EditorEventsHandlers';
import { ChangeControlSection } from '../../../types/DocumentCreate';
import { navigateToSection } from '../../../util/DocumentNavigationUtils';
import { DocumentPresentationUtils } from '../../../util/DocumentPresentationUtils';
import styles from './TableOfContents.module.css';

export const TableOfContents: React.FC<{
  sections: QualioDocumentSection[] | ChangeControlSection[];
  sectionTitlesVisible: boolean;
  editorName?: EditorName;
}> = ({
  sections,
  sectionTitlesVisible,
  editorName = 'documentEditorContent',
}) => {
  const handleItemClick = useCallback(
    (item: QMenuItemType) => {
      const index = parseInt(item.id) - 1;
      navigateToSection(index, editorName);
    },
    [editorName],
  );

  const TOCItems: QMenuItemType[] = useMemo(
    () =>
      DocumentPresentationUtils.getDocumentSectionsTitles(sections).map(
        (title, idx) => ({
          id: title.split('.')[0],
          label: title,
          'data-cy': `toc-element-${idx + 1}`,
        }),
      ),
    [sections],
  );

  const { newDocumentLayout } = useFlags();

  if (newDocumentLayout) {
    return (
      <QBox
        height="fit-content"
        p="4"
        id="documentTocControl"
        data-cy="documentTocControl"
      >
        {sectionTitlesVisible && (
          <QMenuButton
            items={TOCItems}
            onItemClick={handleItemClick}
            variant="icon"
            buttonLabel=""
            aria-label="toc-button"
            iconName="Menu"
            data-cy="toc-button"
          ></QMenuButton>
        )}
      </QBox>
    );
  }

  return (
    <QBox
      id="documentTocControl"
      data-cy="documentTocControl"
      className={`${styles['document-editor__document__toc-control']}`}
    >
      {sectionTitlesVisible && (
        <QMenuButton
          items={TOCItems}
          onItemClick={handleItemClick}
          variant="icon"
          buttonLabel=""
          aria-label="toc-button"
          iconName="Menu"
          data-cy="toc-button"
        ></QMenuButton>
      )}
    </QBox>
  );
};
