import { AxiosResponse } from 'axios';
import { z } from 'zod';
import { integrationsApiClient } from '../../../../api';
import { Source } from '../types';
import { IntegrationConfig } from '../types/integrationConfig';
import { OAuthConfig, ZOAuthConfig } from '../types/oauthConfig';

export const IntegrationsAPI = {
  async getAccessToken(
    companyId: number,
    source: Source,
  ): Promise<OAuthConfig> {
    const response = await integrationsApiClient.get<OAuthConfig>(
      `/company/${companyId}/oauth/${source}/accessToken`,
    );
    return z.object({ data: ZOAuthConfig }).parse(response.data).data;
  },

  async isSourceEnabled(
    source: IntegrationConfig['integration'],
    companyId: number,
  ): Promise<boolean> {
    return integrationsApiClient
      .get(`/company/${companyId}/integrations`)
      .then((res: AxiosResponse) => {
        return !!res.data.data.find(
          (o: IntegrationConfig) => o.integration === source,
        );
      });
  },
} as const;
