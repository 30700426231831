import React, { useEffect, useRef, useState } from 'react';

export type ToolbarWrapperProps = {
  id?: string;
  className?: string;
  widthRef: React.RefObject<HTMLElement>;
};

/**
 * A wrapper component that ensures the toolbar is always visible.
 * The toolbar will be fixed to the top of the screen when the user scrolls past it.
 * Its width is synced to the given `widthRef` element.
 *
 */
export const ToolbarWrapper: React.FC<ToolbarWrapperProps> = ({
  children,
  id = 'toolbarWrapper',
  className,
  widthRef,
}) => {
  const toolbarRef = useRef<HTMLDivElement>(null);
  const placeholderRef = useRef<HTMLDivElement>(null);
  const lastOffsetRef = useRef<number | null>(null);
  const [forcedRenderCount, setForceRender] = useState(0);

  useEffect(() => {
    // We can't do anything without a valid ref.
    if (!toolbarRef.current) {
      return;
    }

    const toolbar = toolbarRef.current;
    const placeholder = placeholderRef.current;

    // Calculate the toolbars position from top of HTML document
    const getOffsetTop = (element: HTMLElement): number => {
      let offset = element.offsetTop;
      if (element.offsetParent) {
        offset += getOffsetTop(element.offsetParent as HTMLElement);
      }
      return offset;
    };

    // Get current offset and update state if needed
    const getCurrentOffset = (): number => {
      if (!toolbar) return 0;

      const currentOffset = getOffsetTop(toolbar);

      // If we get a non-zero offset, store it
      if (currentOffset > 0) {
        lastOffsetRef.current = currentOffset;
        return currentOffset;
      }

      // If we get a zero offset but have a last known good offset, use that
      return lastOffsetRef.current ?? 0;
    };

    // Get initial offset
    const originalOffsetTop = getCurrentOffset();

    if (originalOffsetTop === 0) {
      // We have not been layed out yet
      // (we might be in a hidden tab or
      // have just switched tab).
      // We will try again in 0.5s.
      const handle = setTimeout(() => setForceRender((n) => n + 1), 500);
      return () => {
        clearTimeout(handle);
      };
    }

    // Most likely these are undefined but we'll be
    // defensive about it just in case. When we stop
    // manually updating the toolbar position,
    // we'll restore these values.
    const originalPositionStyle = toolbar.style.position;
    const originalWidthStyle = toolbar.style.width;

    // Creates an observer that will sync the toolbar width
    // to the width of the element being observed.
    let observed: HTMLElement | null = null;

    const widthObserver = new ResizeObserver((entries) => {
      // Only sync width when the toolbar is fixed
      if (toolbar.style.position === 'fixed') {
        const newWidth = entries[0].borderBoxSize[0].inlineSize;
        toolbar.style.width = `${newWidth}px`;
      }
    });

    const updatePlaceholder = () => {
      if (placeholder && toolbar) {
        // Update placeholder height to match toolbar
        placeholder.style.height = `${toolbar.offsetHeight}px`;
      }
    };

    // Also observe height changes on the toolbar itself
    const heightObserver = new ResizeObserver(updatePlaceholder);
    heightObserver.observe(toolbar);

    // Initial placeholder setup
    updatePlaceholder();

    const onScroll = () => {
      // Always get fresh offset when checking scroll position
      const currentOffset = getCurrentOffset();
      // If we have scrolled beyond the top of the toolbar,
      // start managing its width and position.
      if (window.scrollY > currentOffset) {
        toolbar.style.position = 'fixed';
        toolbar.style.top = '0';
        if (placeholder) {
          placeholder.style.display = 'block';
        }
        if (!observed && widthRef.current) {
          observed = widthRef.current;
          widthObserver.observe(observed);
        }
      } else {
        // If we have scrolled back to the top, stop managing
        // the toolbar's width and position.
        if (observed) {
          widthObserver.unobserve(observed);
          observed = null;
        }
        toolbar.style.position = originalPositionStyle;
        toolbar.style.width = originalWidthStyle;
        if (placeholder) {
          placeholder.style.display = 'none';
        }
      }
    };

    window.addEventListener('scroll', onScroll);

    return () => {
      widthObserver.disconnect();
      heightObserver.disconnect();
      window.removeEventListener('scroll', onScroll);
    };
  }, [toolbarRef, widthRef, forcedRenderCount]);

  return (
    <>
      <div ref={placeholderRef} style={{ display: 'none' }} />
      <div ref={toolbarRef} className={className} id={id}>
        {children}
      </div>
    </>
  );
};
