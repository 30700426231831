import {
  QBox,
  QFlex,
  QIconButton,
  QSpacer,
  QTooltip,
} from '@qualio/ui-components';
import { forwardRef, ReactNode, useRef } from 'react';
import { EditorName } from '../../../config/handlers';
import { BackToTop } from '../BackToTop';
import { EditorStatus } from '../DocumentOverview/Context';
import { TableOfContents } from '../TableOfContents';
import styles from './DocumentLayout.module.css';

interface DocumentLayoutProps {
  isEditMode?: boolean;
  sections?: any[];
  sectionTitlesVisible?: boolean;
  commentsPermission?: number;
  editorStatus?: EditorStatus;
  editorName?: EditorName;
  children: ReactNode;
  contentId?: string;
  commentControlId?: string;
  sidebarId?: string;
  dataCy?: {
    content?: string;
    commentControl?: string;
    commentButton?: string;
    commentSidebar?: string;
  };
  showComments?: boolean;
  showTOC?: boolean;
}

export const DocumentLayout = forwardRef<HTMLDivElement, DocumentLayoutProps>(
  (
    {
      isEditMode = false,
      sections = [],
      sectionTitlesVisible = true,
      commentsPermission = 0,
      editorName,
      editorStatus,
      children,
      contentId,
      commentControlId,
      sidebarId,
      dataCy,
      showComments = true,
      showTOC = true,
    }: DocumentLayoutProps,
    ref,
  ) => {
    const containerRef = useRef<HTMLDivElement>(null);
    return (
      <QFlex
        justifyContent="center"
        bg="gray.50"
        border="1px"
        borderTop={isEditMode ? 'none' : '1px'}
        borderColor="gray.200"
        borderRadius={isEditMode ? '0 0 4px 4px' : '4px'}
        ref={ref}
        className={`${styles['document-content-container']}`}
        overflowY="hidden"
      >
        <QFlex
          direction="column"
          position="absolute"
          left="0"
          height="100%"
          ref={containerRef}
        >
          {showTOC && (
            <TableOfContents
              editorName={editorName}
              sections={sections}
              sectionTitlesVisible={sectionTitlesVisible}
            />
          )}
          <QSpacer height="100%" />
          <BackToTop parentRef={containerRef} />
        </QFlex>
        <QBox
          my="4"
          ml={showComments ? '16' : '0'}
          border="1px"
          borderColor="gray.200"
          boxShadow="md"
          id={contentId}
          data-cy={dataCy?.content}
          className={`${styles['document-section-content']} document ${
            !isEditMode ? 'non-editable-mode' : ''
          }`}
        >
          {children}
        </QBox>

        {showComments && (
          <>
            <QBox
              p="4"
              hidden={commentsPermission <= 1}
              id={commentControlId}
              data-cy={dataCy?.commentControl}
              className={`${styles['document-comment-control']}`}
            >
              <QTooltip
                label={'Select content to add a comment'}
                placement={'top'}
              >
                <p>
                  <QIconButton
                    iconName={'MessageSquare'}
                    aria-label="icon-button"
                    size="sm"
                    data-cy={dataCy?.commentButton}
                  />
                </p>
              </QTooltip>
            </QBox>

            <QBox
              position="absolute"
              right="4"
              id={sidebarId}
              data-cy={dataCy?.commentSidebar}
              className={`${styles['document-sidebar']} ${
                editorStatus === EditorStatus.CONNECTING ? 'ck-hidden' : ''
              } transition_on_click`}
            />
          </>
        )}
      </QFlex>
    );
  },
);
