import { getDocumentQris, getQRI } from '@qualio/search-qri';
import {
  QBox,
  QIconButton,
  QTooltip,
  useReferenceDrawer,
} from '@qualio/ui-components';
import { useFlags } from 'launchdarkly-react-client-sdk';
import React, { useCallback, useState } from 'react';
import { QualioDocument } from '../../../../../api/model/document';

export const RelatedRecordsButton: React.FC<{
  document: QualioDocument;
}> = ({ document }) => {
  // focused qri properties: state variable to trigger the opening of reference drawer
  const [qri, setQri] = useState<string | undefined>(undefined);
  const [additionalQris, setAdditionalQris] = useState<string[] | undefined>(
    undefined,
  );

  const { documentEditorReferenceDrawer } = useFlags();

  const oncloseHandler = () => {
    setQri(undefined);
    setAdditionalQris(undefined);
  };

  useReferenceDrawer(
    {
      isLoading: false,
      label: document.title,
      qri: qri ?? '',
      qriProperties: undefined,
      additionalQris,
      displayViewDetailsLink: false,
    },
    oncloseHandler,
  );

  const onClick = useCallback(() => {
    const qriIdentifier = getQRI({
      companyId: document.company_id.toString(),
      domain: 'documents',
      domainType: 'document',
      versionId: document.id.toString(),
      id: document.document_matrix_id,
    });

    let documentQris: string[] = [];

    try {
      documentQris = getDocumentQris(
        {
          companyId: document.company_id,
          documentMatrixId: document.document_matrix_id,
          id: document.id,
        },
        process.env.REACT_APP_QRI_SERVICE_URL ?? '',
      );
    } catch (err) {
      console.debug(err);
      console.error('Error getting document qris');
    }

    setQri(qriIdentifier);
    setAdditionalQris(
      documentQris.filter((qri) => !qri.includes(qriIdentifier)),
    );
  }, [document]);

  if (!documentEditorReferenceDrawer) {
    return null;
  }

  return (
    <QTooltip label="View related records" placement="top">
      <QBox>
        <QIconButton
          data-cy="related-records-button"
          onClick={onClick}
          variant="outline"
          aria-label="view related records"
          iconName="Related"
          aria-describedby="tooltip-3"
        />
      </QBox>
    </QTooltip>
  );
};
