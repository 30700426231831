import { QBox } from '@qualio/ui-components';

export const BannerPlaceholder = () => {
  return (
    <QBox
      pt="20px"
      width="calc(100% + 48px)"
      marginTop="-10px"
      marginLeft="-24px"
    />
  );
};
