import { QBox, QIconButton, QTooltip } from '@qualio/ui-components';
import { useEffect, useState } from 'react';
import { BackToTopWrapper } from './BackToTopWrapper';

interface BackToTopProps {
  parentRef: React.RefObject<HTMLElement>;
}

export const BackToTop: React.FC<BackToTopProps> = ({ parentRef }) => {
  const [isVisible, setIsVisible] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      setIsVisible(window.scrollY > 300);
    };

    window.addEventListener('scroll', handleScroll);
    handleScroll(); // Initial check

    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
  };

  return (
    <BackToTopWrapper parentRef={parentRef}>
      <QBox
        p="4"
        opacity={isVisible ? '1' : '0'}
        visibility={isVisible ? 'visible' : 'hidden'}
        transition="all 0.3s ease-in-out"
        zIndex="10"
        data-cy="back-to-top-button"
      >
        <QTooltip label="Back to top" placement="top">
          <QBox>
            <QIconButton
              variant="outline"
              onClick={scrollToTop}
              aria-label="Back to top"
              iconName="ArrowUp"
              size="sm"
            />
          </QBox>
        </QTooltip>
      </QBox>
    </BackToTopWrapper>
  );
};
