import { CurrentUserProvider } from '@qualio/ui-components';
import { CurrentUser } from '@qualio/ui-components/lib/types/CurrentUser';
import {
  AsyncProviderConfig,
  asyncWithLDProvider,
} from 'launchdarkly-react-client-sdk';
import ReactDOM from 'react-dom';
import { QueryClient, QueryClientProvider } from 'react-query';
import { userApi } from './api/user';
import { App } from './App';
import {
  INDEX_UNMOUNT_EDITOR_ERROR,
  logCustomError,
} from './messages/LogErrorMessages';
import { getDocumentEditorGlobalWindowObject } from './util/AppUtils';
import { AiPocApp } from './views/components/AiPoc/AiPocApp';
import { ErrorBoundary } from './views/ErrorBoundary';

const getWindow = () => window as any;

getWindow().renderDocumentEditor = async (
  containerId: string,
  currentUser: CurrentUser | null,
) => {
  if (!currentUser) {
    currentUser = await userApi.fetchCurrentUser();
  }
  const launchDarklyContext: { [key: string]: string | number } = {
    kind: 'user',
    key: String(currentUser.companyId),
    loggedInUserId: String(currentUser.userId),
    createdTime: currentUser.company.createdTime,
    status: currentUser.company.status,
  };
  if (currentUser.company.pricingPlan) {
    launchDarklyContext['pricingPlan'] = currentUser.company.pricingPlan;
  }

  const ldProviderConfig: AsyncProviderConfig = {
    clientSideID: process.env.REACT_APP_LAUNCH_DARKLY_KEY as string,
    context: launchDarklyContext,
    options: {},
  };

  if (process.env.NODE_ENV === 'development') {
    ldProviderConfig.flags = {
      changeRequestTransition: true,
      editorAi: true,
      compareAgainstUpdates: true,
      qePricingAndPackaging2024: true,
      santitizeHtmlView: true,
      multilevelListEnabled: true,
      compareAgainstNewDiff: true,
      realTimeCollaboration: true,
      documentActivityLog: true,
      mtbeRetrieveCollabComments: true,
      auditControlledExports: true,
      documentEditorReferenceDrawer: true,
      mediaEmbedEnabled: true,
      smartlinkEverything: true,
      updateSmartlinkTitles: true,
      newDocumentLayout: true,
    };
  }

  const LDProvider = await asyncWithLDProvider(ldProviderConfig);

  const editorWindowData = getDocumentEditorGlobalWindowObject();

  editorWindowData.containerHtmlElement = document.getElementById(containerId);

  const queryClient = new QueryClient();

  if (
    containerId === 'generatecc-container' ||
    containerId === 'generatetrainingassessment-container'
  ) {
    return ReactDOM.render(
      <ErrorBoundary>
        <LDProvider>
          <QueryClientProvider client={queryClient}>
            <CurrentUserProvider user={currentUser}>
              <AiPocApp currentUser={currentUser} containerId={containerId} />
            </CurrentUserProvider>
          </QueryClientProvider>
        </LDProvider>
      </ErrorBoundary>,
      document.getElementById(containerId),
    );
  }

  return ReactDOM.render(
    <ErrorBoundary>
      <LDProvider>
        <QueryClientProvider client={queryClient}>
          <CurrentUserProvider user={currentUser}>
            <App currentUser={currentUser} />
          </CurrentUserProvider>
        </QueryClientProvider>
      </LDProvider>
    </ErrorBoundary>,
    document.getElementById(containerId),
  );
};

getWindow().unmountDocumentEditor = (containerId: string) => {
  try {
    const containerHtmlElement =
      document.getElementById(containerId) ??
      getDocumentEditorGlobalWindowObject().containerHtmlElement;
    if (containerHtmlElement) {
      ReactDOM.unmountComponentAtNode(containerHtmlElement);
    }
  } catch (e) {
    logCustomError(INDEX_UNMOUNT_EDITOR_ERROR, { error: e });
    // even with the if statements its possible we have a race condition that is the if states the element exists BUT
    // then before we remove it the Application container has removed it
  }
  getDocumentEditorGlobalWindowObject().loaded = false;
};

getWindow().renderGeneratecc = async (
  containerId: string,
  currentUser: CurrentUser | null,
) => {
  getWindow().renderDocumentEditor(containerId, currentUser);
};

getWindow().unmountGeneratecc = async (containerId: string) => {
  getWindow().unmountDocumentEditor(containerId);
};

getWindow().renderGeneratetrainingassessment = async (
  containerId: string,
  currentUser: CurrentUser | null,
) => {
  getWindow().renderDocumentEditor(containerId, currentUser);
};

getWindow().unmountGeneratetrainingassessment = async (containerId: string) => {
  getWindow().unmountDocumentEditor(containerId);
};
