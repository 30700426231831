import { QIconButton, QTooltip } from '@qualio/ui-components';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { forwardRef, ReactNode, useContext } from 'react';
import { EditorMode } from '../../../../util/EditModeUtils';
import { DocumentLayout } from '../../DocumentLayout/DocumentLayout';
import { TableOfContents } from '../../TableOfContents/index';
import {
  DocumentOverviewContext,
  EditorStatus,
  EditorStatusContext,
} from '../Context';
import styles from './DocumentEditor.module.css';

export const DocEditorSideBarWrapper = forwardRef<
  HTMLDivElement,
  { children: ReactNode }
>(({ children }, ref) => {
  const { qualioDocument, currentEditorMode, commentsPermission } = useContext(
    DocumentOverviewContext,
  );

  const { docEditorStatus } = useContext(EditorStatusContext);
  const { newDocumentLayout } = useFlags();
  const allowScrollOutOfView = newDocumentLayout;

  const isEditMode =
    currentEditorMode !== EditorMode.VIEW &&
    docEditorStatus !== EditorStatus.CONNECTING;

  if (newDocumentLayout) {
    return (
      <DocumentLayout
        ref={ref}
        isEditMode={isEditMode}
        sections={qualioDocument.sections}
        sectionTitlesVisible={qualioDocument.text_sections_titles_visible}
        commentsPermission={commentsPermission}
        editorStatus={docEditorStatus}
        contentId="documentEditorContent"
        commentControlId="documentCommentControl"
        sidebarId="commentsSidebar"
        dataCy={{
          content: 'documentEditorContent',
          commentControl: 'documentCommentControl',
          commentButton: 'comment-button',
          commentSidebar: 'commentsSidebar',
        }}
      >
        {children}
      </DocumentLayout>
    );
  }

  const visibilityClass = `document-editor__document__content-container__show`;
  const borderClass = isEditMode
    ? styles['document-editor__document__content-container-border']
    : '';

  return (
    <div
      ref={ref}
      className={`${styles['document-editor__document__content-container']} ${styles[visibilityClass]} ${borderClass}`}
    >
      {
        <TableOfContents
          sections={qualioDocument.sections}
          sectionTitlesVisible={qualioDocument.text_sections_titles_visible}
        />
      }
      <div
        id="documentEditorContent"
        data-cy="documentEditorContent"
        className={`${
          styles['document-editor__document__section-content']
        } document ${!isEditMode ? 'non-editable-mode' : ''}`}
      >
        {children}
      </div>
      <div
        hidden={commentsPermission <= 1}
        id="documentCommentControl"
        data-cy="documentCommentControl"
        className={`${styles['document-editor__document__comment-control']} hidden`}
      >
        <QTooltip label={'Select content to add a comment'} placement={'top'}>
          <p>
            <QIconButton
              iconName={'MessageSquare'}
              aria-label="icon-button"
              size="md"
              data-cy="comment-button"
            />
          </p>
        </QTooltip>
      </div>
      <div
        id="commentsSidebar"
        data-cy="commentsSidebar"
        className={`${styles['document-editor__document__sidebar']} ${
          docEditorStatus === EditorStatus.CONNECTING ? 'ck-hidden' : ''
        }  ${!allowScrollOutOfView ? 'transition_on_click' : ''}`}
      ></div>
    </div>
  );
});
