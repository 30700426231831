import { QBox } from '@qualio/ui-components';
import React, { useEffect, useRef, useState } from 'react';

interface BackToTopWrapperProps {
  children: React.ReactNode;
  parentRef: React.RefObject<HTMLElement>;
}

/**
 * A wrapper component that controls the BackToTop button's positioning
 * The button will be fixed to the bottom of the screen until it reaches the bottom of the parent
 *
 */
export const BackToTopWrapper: React.FC<BackToTopWrapperProps> = ({
  children,
  parentRef,
}) => {
  const wrapperRef = useRef<HTMLDivElement>(null);
  const [isFixed, setIsFixed] = useState(true);
  const [forcedRenderCount, setForceRender] = useState(0);

  // Store last known parent offset
  const lastParentOffsetRef = useRef<number | null>(null);

  useEffect(() => {
    // We can't do anything without a valid ref.
    if (!parentRef.current) {
      return;
    }

    const parent = parentRef.current;

    // Calculate the parent's bottom position from top of HTML document
    const getOffsetTop = (element: HTMLElement): number => {
      let offset = element.offsetTop;
      if (element.offsetParent) {
        offset += getOffsetTop(element.offsetParent as HTMLElement);
      }
      return offset;
    };

    const getCurrentOffset = (): number => {
      const currentOffset = getOffsetTop(parent);

      // If we get a non-zero offset, store it
      if (currentOffset > 0) {
        lastParentOffsetRef.current = currentOffset;
        return currentOffset;
      }

      // If we get a zero offset but have a last known good offset, use that
      return lastParentOffsetRef.current ?? 0;
    };

    const initialParentOffset = getCurrentOffset();

    if (initialParentOffset === 0) {
      // Haven't been laid out yet, try again in 0.5s
      const handle = setTimeout(() => setForceRender((n) => n + 1), 500);
      return () => clearTimeout(handle);
    }

    const BOTTOM_OFFSET_PX = 6 * 4;

    const checkPosition = () => {
      // Get parent's bottom position
      const parentOffset = getCurrentOffset();
      const parentOffsetBottom = parentOffset + parent.offsetHeight;

      // Calculate where our fixed button's bottom is in document coordinates
      const viewportHeight = window.innerHeight;
      const fixedButtonBottom =
        window.scrollY + viewportHeight - BOTTOM_OFFSET_PX;

      // If fixed button bottom would pass parent bottom, make it relative
      setIsFixed(fixedButtonBottom < parentOffsetBottom);
    };

    window.addEventListener('scroll', checkPosition);
    window.addEventListener('resize', checkPosition);

    // Initial check
    checkPosition();

    return () => {
      window.removeEventListener('scroll', checkPosition);
      window.removeEventListener('resize', checkPosition);
    };
  }, [wrapperRef, parentRef, forcedRenderCount]);

  return (
    <QBox
      ref={wrapperRef}
      position={isFixed ? 'fixed' : 'relative'}
      bottom={isFixed ? '6' : '0'}
    >
      {children}
    </QBox>
  );
};
